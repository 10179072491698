import { makeProtectedRequest } from "./makeRequest";

export const fetchCryptoUser = async (data, executeRecaptcha) => {
  const protectedRequest = makeProtectedRequest(executeRecaptcha);
  
  return await protectedRequest.callProtectedApi(
    `/api/crypto-users`,
    {
      action: 'find',
      wallet: data.walletAddress,
      signature: data.signature,
    },
    'POST',
    'fetchCryptoUser',
  )
 
};

export const fetchWalletSession = async (wallet, executeRecaptcha) => {

  const protectedRequest = makeProtectedRequest(executeRecaptcha);

  // return await makeRequest.get(
  //   `/api/crypto-users?action=session&wallet=${encodeURIComponent(wallet)}`
  // );

  return await protectedRequest.callProtectedApi(
    `/api/crypto-users`,
    {
      action: 'session',
      wallet: wallet,
    },
    'POST',
    'fetchWalletSession',
  )
};




