import React, { createContext, useContext, useState } from 'react';

const MintingContext = createContext({
  isMinting: false,
  hasMinted: false,
  errorMinting: false,
  errorMessage: null,
  setIsMinting: () => {},
  setHasMinted: () => {},
  setErrorMinting: () => {},
  setErrorMessage: () => {}
});

export function useMinting() {
  const context = useContext(MintingContext);
  if (!context) {
      throw new Error('useMinting must be used within a MintingProvider');
  }
  return context;
}

export function MintingProvider({ children }) {
  const [isMinting, setIsMinting] = useState(false);
  const [hasMinted, setHasMinted] = useState(false);
  const [errorMinting, setErrorMinting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  //console.log("MintingProvider rendered", { isMinting });

  return (
    <MintingContext.Provider value={{ isMinting, setIsMinting, hasMinted, setHasMinted, errorMinting,setErrorMinting,errorMessage,setErrorMessage }}>
      {children}
    </MintingContext.Provider>
  );
}
