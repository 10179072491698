import React, { createContext, useState, useEffect, useContext } from 'react';
import {useConnectWallet, useWallets } from '@web3-onboard/react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddress } from '../store/authSlice';
import { fetchCryptoUser, fetchWalletSession } from '../strapi/strapiService'; 
import { Web3Provider } from '@ethersproject/providers';
import { setError } from './errorSlice';
import { SignatureDialogContext } from './SignatureDialogContext';  
import {NetworkSwitchDialogContext} from './NetworkSwitchDialogContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const WalletContext = createContext();


export const WalletProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [
    { wallet, connecting },
    connect,
    disconnect,
  ] = useConnectWallet();

  const connectedWallets = useWallets()
  const openSignatureDialog = useContext(SignatureDialogContext);
  const openNetworkSwitchDialog = useContext(NetworkSwitchDialogContext);

  const [userId, setUserID] = useState(null)
  const [isSigning, setIsSigning] = useState(false);


  const address = useSelector((state) => state.auth.address);

  useEffect(() => {
    const handleWallets = async () => {
      if (!connectedWallets.length) {
        // console.log("Wallet disconnected")
        dispatch(setAddress(null)); 
        return;
      }
      if(connectedWallets[0]?.accounts?.length > 1){
        dispatch(setAddress(connectedWallets[0]?.accounts[0]))
      }
    };
  
    handleWallets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWallets, wallet, disconnect, connect]);



  useEffect(() => {
    if(wallet){
      if(wallet?.accounts[0]?.address?.toString() !== address?.address?.toString()){
        if (!isSigning) {
        fetchChainIdAndUserData();
        }
      }
      
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  async function getSession() {
    try {
      const session = await fetchWalletSession(wallet.accounts[0]?.address?.toString(), executeRecaptcha);
      if (session?.data?.wallet === false || session?.data?.wallet !== wallet.accounts[0]?.address?.toString()) {
        // No session or wallet mismatch, ask the user to sign a message
        const signing = await openSignatureDialog();
        if (!signing) {
          handleDisconnect();
          return;
        }
        const signature = await getSignature(wallet, session?.data?.key);
        // console.log(signature)
        return {
          walletAddress: wallet.accounts[0]?.address?.toString(),
          signature: signature
        };
      } else {
        return {
          walletAddress: wallet.accounts[0]?.address?.toString(),
          signature: ""
        };
      }
    } catch (error) {
      // console.error(error);
      errorHandling(error);
      handleDisconnect();
      return null;
    }
  }

  async function getSignature(wallet, key, message = 'Please sign this message to confirm your identity.') {
    const appName = 'Excelsior App';
    const fullMessage = `${appName}: ${message} Wallet: ${wallet?.accounts[0]?.address?.toString()} Id: ${key}`;
    const signer = new Web3Provider(window.ethereum).getSigner();
    const signature = await signer.signMessage(fullMessage);
    return signature;
  }



  const fetchChainIdAndUserData = async () => {
    //console.log(connectedWallets)
    if(!connectedWallets.length){return;}
    if (wallet) {
      setIsSigning(true);
      try {
        
        const switchreq = await checkNetworkSwitch();
        if(!switchreq){
          handleDisconnect();
          return;
        }
        const userData = await getSession();

        if(userData && executeRecaptcha){
          fetchCryptoUser(userData, executeRecaptcha).then((cryptoUser) => {

            console.log("fetched ", cryptoUser)
            
            if (cryptoUser?.status !== 200) {
              // Handle bad request error
              // console.log("STATUS?? ", cryptoUser?.status)
              errorHandling(cryptoUser);
              handleDisconnect();
            } else {

              // console.log("id? ", cryptoUser?.data?.id)
            if(cryptoUser?.data?.id){
              setUserID(cryptoUser?.data?.userId);
              let banned = Boolean(cryptoUser?.data?.banned)
              // console.log("banned? ", banned)
              if(banned){
                handleDisconnect();
              }else{
                dispatch(setAddress(wallet?.accounts[0]));
              } 
            }else{
              handleDisconnect();
            }
          }
          }).catch((error) => {
            console.log("error -----> ")
            errorHandling(error);
            handleDisconnect();
          });
        }
      } catch (error) {
        console.log("1 error -----> ")
        errorHandling(error);
      } finally {
        setIsSigning(false);
      }
    } else {
      console.log("NO ERROR")
      dispatch(setAddress(null)); 
      setIsSigning(false);
    }
  };

  const handleConnect = async () => {
    try {
       // If a wallet is already connected, disconnect it
    if (wallet) {
      await disconnect(wallet);
      dispatch(setAddress(null)); 
    }
    // Connect the new wallet
      await connect();
    } catch (error) {
      errorHandling(error);
    }
  }
  const handleDisconnect = async () => {
    try {
      await disconnect(wallet);
      dispatch(setAddress(null)); 
    } catch (error) {
      errorHandling(error);
    }
  }

  async function requestVerification() {
    if (!wallet) {
      // User is not logged in
      return false;
    }
  
    const switchreq = await checkNetworkSwitch();
    if(!switchreq){
      handleDisconnect();
      return false;
    }
    // User is logged in and on the correct chain
    return true;
  }

  async function checkNetworkSwitch(){
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const desiredChainId = process.env.NODE_ENV === "production" ? "0x2105" : "0x14a33";
      if (chainId !== desiredChainId) {
        // User is not on the correct chain, attempt to switch
        const switching = await openNetworkSwitchDialog();
        if (!switching) {
          handleDisconnect();
          return false;
        }
        try {
          const result = await requestNetworkChange(desiredChainId);
          return result;
        } catch (error) {
          // User rejected the network switch
          return false;
        }
    }else{
      return true;
    }
  }

  async function requestNetworkChange(chainId) {
    return new Promise(async (resolve, reject) => {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `${chainId}` }], // chainId must be in hexadecimal
        });
        resolve(true);
      } catch (switchError) {
        
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: process.env.NODE_ENV === "production" ? "0x2105" : "0x14a33", // Base Mainnet/Testnet Chain ID in hexadecimal
                chainName: process.env.NODE_ENV === "production" ? 'Base Mainnet' : 'Base Goerli', // Network Name
                nativeCurrency: {
                  name: 'ETH', // Currency Name
                  symbol: 'ETH', // Currency Symbol
                  decimals: 18
                },
                rpcUrls: [process.env.NODE_ENV === "production" ? 'https://mainnet.base.org' : 'https://goerli.base.org'], // RPC Endpoint
                blockExplorerUrls: [process.env.NODE_ENV === "production" ? 'https://basescan.org' : 'https://goerli.basescan.org'] // Block Explorer URL
              }],
            });
            resolve(true);
          } catch (addError) {
            errorHandling("Problem adding Base Mainnet to wallet. " + addError.message);
            resolve(false);
          }
        } else {
          errorHandling("User rejected the request to switch networks. You must be on Base Mainnet to use this service.");
          resolve(false);
        }
      }
    });
  }

  async function errorHandling(error){
    dispatch(setError(error));
    // if (error?.response) {
    //   console.log(error?.response);
    //   // The request was made and the server responded with a status code
    //   // that falls out of the range of 2xx
    //   dispatch(setError(error?.response?.data?.error?.message + ": " + error?.response?.data?.error?.details));
    // } else if (error?.request) {
    //   // The request was made but no response was received
    //   dispatch(setError('Something went wrong. ' + error?.request?.statusText));
    // } else if (error?.message) {    
    //   // Something happened in setting up the request that triggered an Error
    //   dispatch(setError('Something went wrong. ' + error.message));
    // }else {    
    //   // Something happened in setting up the request that triggered an Error
    //   dispatch(setError('Something went wrong. ' + error));
    // }
    // Log the error for debugging purposes
  }


  const value = {
    wallet,
    connect: handleConnect,
    disconnect: handleDisconnect,
    connecting,
    userId,
    requestVerification,
    getSession,
  };

  return <WalletContext.Provider value={value}>
  {children}
  </WalletContext.Provider>;
};

export default WalletContext;