
import {useRef, useEffect, useState} from 'react'
import {Box, Button, Divider, IconButton, Typography, Stack, Link} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {useTheme} from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import styled from '@emotion/styled';
// import InfoTooltip from '../components/InfoTooltip'
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import WalletFab from '../purchase/WalletFab';
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
  selectTotalCount,
  refreshCart

} from '../store/cartSlice';
import { motion, useAnimation } from 'framer-motion';
import Decimal from 'decimal.js';
import baseWordmarkImage from '../svg/Base_Wordmark_White.svg';
import CartProcessor from './CartProcessor';
import { useMinting } from './MintingContext';
import { useNavigate } from 'react-router-dom';
import {makeRequest} from '../strapi/makeRequest'

// import {
//   GetMediaMaxWidthSmallDevice,
// } from "../store/ExcelsiorDataContext";

// import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import { useEffectOnceWhen } from 'rooks';


const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function ExcelsiorCartMenu() {

  const {isMinting} = useMinting();

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const controls = useAnimation();
  const prevTotalPriceRef = useRef();
  // const matchesSmallDevice = GetMediaMaxWidthSmallDevice();

  const cart = useSelector((state) => state.cart.cart);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);
  const totalCountInCart = useSelector(selectTotalCount)


  const address = useSelector((state) => state.auth.address);
  const loggedIn = Boolean(address);

  const strapi_url = process.env.REACT_APP_STRAPI_URL;

  const [cartVersion, setCartVersion] = useState(0);


  const totalUSDPrice = cart.reduce((total, item) => {
    return Math.round(((total + item.count * item.converted_price) + Number.EPSILON) * 100) / 100;
}, 0).toFixed(2);


const totalEthPrice = cart.reduce((total, item) => {
  if (item.price !== null) {
    const convertedPrice = new Decimal(item.price);
    const subtotal = total.plus(convertedPrice.times(item.count));
    return subtotal;
  }
  return total;
}, new Decimal(0)).toFixed(7);
  
  

  const handleClickAway = () => {
    if(isMinting) return;
    if(isCartOpen){
        dispatch(setIsCartOpen());
    }
  };

  // async function fetchLatestItemData(itemId) {
  //   const response = await makeRequest.get(`/api/items?filters[id][$eq]=${itemId}&populate=image`);
  //   return response.data.results[0];
  // }

  async function fetchLatestItemData(itemId) {
    const cacheKey = `itemData_${itemId}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      return JSON.parse(cachedData);
    } else {
      const response = await makeRequest.get(`/api/items?filters[id][$eq]=${itemId}&populate=image`);
      const itemData = response.data.results[0];
      localStorage.setItem(cacheKey, JSON.stringify(itemData));
      return itemData;
    }
  }


  useEffect(() => {
    if (prevTotalPriceRef.current !== totalEthPrice && prevTotalPriceRef.current !== undefined) {
      controls.start({
        scale: [1, 1.1, 1],
        color: ['#fff', theme.palette.warning.main, '#fff'],
        transition: { duration: 0.5 },
      });
    }

    prevTotalPriceRef.current = totalEthPrice;
  }, [totalEthPrice, controls, theme.palette.warning.main]);

  useEffectOnceWhen(() => {
    if (isCartOpen) {
      const currentVersion = cartVersion; // Capture the current version before starting the async operation

      const updatedCart = cart.map(async (item) => {
        const latestItemData = await fetchLatestItemData(item.id);
        // Preserve the count from the old item data
        latestItemData.count = item.count;
        return latestItemData;
      });
      Promise.all(updatedCart).then((updatedCartItems) => {
        // Check if the cart version has changed during the fetch
      if (currentVersion === cartVersion) {
        dispatch(refreshCart(updatedCartItems));
        // Optionally, increment the version to indicate a successful update
        setCartVersion(v => v + 1);
      }
      });
    }
  }, [isCartOpen, cartVersion, cart, dispatch]);


  return (

    <div className='shopping-cart'>
    <Box //overlay
        display={isCartOpen ? "block" : "none"}
        backgroundColor="rgba(0,0,0,0.4)"
        position="fixed"
        zIndex={10}
        width="100%"
        height="100%"
        left="0"
        top="0"
        overflow="auto"
       
        >
        {/*MODAL*/}
        <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
    <Box
      display={isCartOpen ? "block" : "none"}   
      position="fixed"
      right="0"
      bottom="0"
      width="max(375px, 30%)"
      height="100%"
      zIndex={20}
      bgcolor="#26282d"
    >
      <Box padding="3rem" overflow="auto" height="calc(100% - 70px)">
        <FlexBox>
          <Typography variant="h3">YOUR CART ({totalCountInCart})</Typography>
          <IconButton onClick={()=>dispatch(setIsCartOpen(false))}>
            <CloseIcon/>
          </IconButton>
        </FlexBox>


        <Box>
          {cart.map((item, index) => (
            <Box key={`${item.id}-${index}`}>
              <FlexBox p="15px 0">
                <Box>
                  <img 
                    alt={item?.name}
                    height="100px"
                    src={strapi_url+`${item?.image?.url}`}

                  />
                </Box>
                 <Box flex="1 1 60%" ml="10%">
                  <FlexBox >
                    <Typography>
                      {item?.name}
                    </Typography>
           
                    <IconButton onClick={()=>dispatch(removeFromCart({id:item.id}))} edge="end">
                      <DeleteOutlineOutlinedIcon color="warning"/>
                    </IconButton>
                
                  </FlexBox>
                  {/* <Typography variant="body2">{item?.shortDescription}</Typography> */}
                  <FlexBox m="15px 0">
                    <Box
                      display="flex"
                      alignItems="center"
                      border="1.5px solid #535252"
                    >
                   
                      <IconButton
                        onClick={() => dispatch(decreaseCount({id:item.id}))}
                        disabled={item.count<=1}
                      >
                        <RemoveIcon />
                      </IconButton>
                      
                   
                      <Typography>{item.count}</Typography>
                      <IconButton
                        onClick={() => dispatch(increaseCount({id:item.id}))}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Stack
                    justifyContent="flex-end"
                    >
                    <Typography variant="body1" 
                    color={theme.palette.text.secondary}
                    textAlign="right"
                    >
                    {/* Ξ {item?.price}  */}
                    Ξ {Math.ceil(item?.price * 1e7) / 1e7.toFixed(7)}
                      </Typography>
               
                    <Typography 
                    variant="body2" 
                    style={{ fontSize: '0.875rem', opacity: '.75' }} 
                    color={theme.palette.text.secondary}
                    textAlign="right"
                    >(${item?.converted_price})</Typography>
                    </Stack>
                  </FlexBox>
                </Box>
              </FlexBox>
              <Divider/>
            </Box>
          ))}
        </Box>
        <Box padding="30px 0">
          <FlexBox justifyContent="space-between">
            <Typography>Total:</Typography>
            <motion.div
            animate={controls}
            style={{ display: "inline-block" }}
          >
            <Typography
                    textAlign="right"
            >
            Ξ {Math.ceil(totalEthPrice * 1e7) / 1e7.toFixed(7)}
            </Typography>
            </motion.div>
          </FlexBox>
          <Stack spacing={1}>
          <Typography
             variant="body2" 
                    style={{ fontSize: '0.875rem', opacity: '.75' }} 
                    color={theme.palette.text.secondary}
                    textAlign="right"
            >(${totalUSDPrice})</Typography>
            {loggedIn?
            <>
              <CartProcessor disabled={cart.length <= 0}/>
              </>
          :
          <>
            {/* <Typography>Wallet must be connected to checkout.</Typography> */}
            <WalletFab type="long" />
            
          </>
          
            }
            {/* <CrossmintPayButton
                projectId="_YOUR_PROJECT_ID_"
                collectionId="_YOUR_COLLECTION_ID_"
                environment="_ENVIRONMENT_"
                mintConfig={{
                  type: "erc-721a",
                  quantity: "_NUMBER_OF_NFTS_",
                  totalPrice: "_PRICE_IN_NATIVE_TOKEN_"
                  // your custom minting arguments...
                }}
                style={{
                  width:"100%", 
                  fontWeight:"normal", 
                  textTransform: "uppercase",
                }}
                disabled={cart.length <= 0}
                getButtonText={(connecting, paymentMethod) =>
                  connecting ? "CONNECTING" : `BUY WITH CREDIT CARD`
                }
              /> */}
             
              <Button disabled={true} endIcon={<CreditCardIcon />} variant="contained" color="primary" style={{ fontWeight: 'bold', width: '100%' }}>Credit card (Coming soon)</Button>
          </Stack>
          <Box>
          <Typography variant="body2" sx={{marginTop:'1rem', marginBottom:'.25rem', fontSize: '0.6rem', opacity:".5"}}>
          USD prices provided are for reference only and are approximate values based on cached ETH to USD rates, therefore USD prices may not be current and could differ at checkout. Customers are responsible for all transaction gas fees. We are not responsible for issues arising from insufficient funds or inadequate gas fees. By making a purchase, you accept our 
          {" "}<Link onClick={() => navigate('/terms')} color="inherit" sx={{cursor: 'pointer'}}>Terms of Use</Link> {" "}
          and 
          {" "}<Link onClick={() => navigate('/privacypolicy')} color="inherit" sx={{cursor: 'pointer'}}>Privacy Policy</Link>.
        </Typography>
          <Box>
          <Link 
            href="https://base.org" 
            target="_blank" 
            rel="noopener noreferrer" 
            underline="none" 
            color="inherit"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
              transition: 'transform 0.3s',
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
              <Stack 
                direction="row" 
                justifyContent="center" 
                spacing={.4}
              >
              <Typography variant="body1">Built on</Typography>
              <img src={baseWordmarkImage} alt="Base Wordmark" style={{width:"3rem"}} />
            </Stack>
            </Link>
          </Box>
          <Stack>
          <Link
              variant="caption"
              color="primary"
              href="https://bridge.base.org/deposit"
              target="_blank"
              rel="noopener noreferrer"
              style={{width: '100%'}}
            >
              Bridge ETH to Base
            </Link> 
            <Box>
            <Link
              variant="caption"
              color="primary"
              href="https://www.coinbase.com/wallet/downloads"
              target="_blank"
              rel="noopener noreferrer"
              style={{width: '100%'}}
            >
              Install Coinbase Wallet
            </Link> 
            <Typography variant="caption" component="span"> or </Typography>
            <Link
              variant="caption"
              color="primary"
              href="https://metamask.io/download/"
              target="_blank"
              rel="noopener noreferrer"
              style={{width: '100%'}}
            >
             MetaMask
            </Link> 
            </Box>
            {/* <Link
              variant="caption"
              color="primary"
              href="https://www.youtube.com/watch?v=0ctngpAJXw0&list=PL0zEUTVaoPOw2WTcEXqX4elCY_EZLZEn9"
              target="_blank"
              rel="noopener noreferrer"
              style={{width: '100%'}}
            >
             I'm a newbie! Help!
            </Link>  */}
            </Stack>
        </Box>
        </Box>
        
      </Box>
    </Box>
    </ClickAwayListener>
    </Box>
    </div>
  );
}
