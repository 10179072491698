import axios from 'axios';


const handleResponseError = (error) => {
  if (error.response && error.response.status === 429) {
    // Handle 429 error here
    console.error('Too many requests. Please try again later.');
  } else {
    // Handle other errors here
    console.error(error);
  }
  return Promise.reject(error);
}

export const makeRequest = axios.create({
  baseURL: process.env.REACT_APP_INKVAULT_DB,
  withCredentials: true,
})


makeRequest.interceptors.response.use(response => response, error => {
  handleResponseError(error);
  throw error;
});


export const makeProtectedRequest = (executeRecaptcha) => {

  const callProtectedApi = async (endpoint, params, method = 'GET', actionName, responseType) => {
    if (!executeRecaptcha) {
      throw new Error('reCAPTCHA not ready');
    }
  
    // Pass the action name to the reCAPTCHA function
    const token = await executeRecaptcha(actionName);
  
    let requestParams = {};
    let requestData = {};

    if (method.toUpperCase() === 'GET') {
      requestParams = { ...params, token };
    } else if (['POST', 'PUT'].includes(method.toUpperCase())) {
      requestData = { data: { ...params, token } };
    }
  
    const response = await makeRequest({
      url: endpoint,
      method: method,
      params: requestParams,
      data: requestData,
      responseType: responseType,
    });
  
    return response;
  };

  return { callProtectedApi };
};



