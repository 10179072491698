import React , {useEffect} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store/ExeclsiorStore';
import { ExcelsiorMain } from './ExcelsiorMain';
import {IssueDataProvider} from './store/ExcelsiorDataContext'
import { useSelector } from 'react-redux';
import { Web3OnboardProvider, init } from '@web3-onboard/react'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseModule from '@web3-onboard/coinbase'
import { useAccountCenter } from '@web3-onboard/react';
import {AnimatePresence} from 'framer-motion'
import { ExcelsiorBackground } from './ExcelsiorBackground';
import { WalletProvider } from './store/WalletContext';
import { SignatureDialogProvider } from './store/SignatureDialogContext';  
import { NetworkSwitchDialogProvider } from './store/NetworkSwitchDialogContext';  
import {
  NotificationContainer
} from "react-notifications";
import Masklogo  from './svg/mask.svg'
import {MintingProvider } from './purchase/MintingContext';


// import {
//   BlockchainTypes,
//   CrossmintEVMWalletAdapter,
// } from "@crossmint/connect";

import BlockingOverlay from './purchase/BlockingOverlay';


function ExcelsiorInner() {
  const mainStage = useSelector(state => state.stage.mainStage); 
  const updateAccountCenter = useAccountCenter();

 
    useEffect(() => {
      if (mainStage === 'HOME') {
        updateAccountCenter({
          enabled: true
        })
      } else {
        updateAccountCenter({
          enabled: false
        })
      }
      return () => {
        updateAccountCenter({
          enabled: false
        })
      }
    }, [mainStage, updateAccountCenter]);

  return <ExcelsiorMain />;
}



export function Excelsior (){


const baseMainnet = {
  id: '0x2105', // The network ID for Base
  token: 'ETH',
  label: 'Base Mainnet',
  rpcUrl: 'https://mainnet.base.org',
}

const baseGoerliMainnet = {
  id: '0x14a33', // The network ID for Base Goerli
  token: 'ETH',
  label: 'Base Goerli Testnet',
  rpcUrl: 'https://goerli.base.org',
}

// const crossmintConnect = new CrossmintEVMWalletAdapter({
//   apiKey: "",
//   chain: BlockchainTypes.ETHEREUM,
// });

// const crossmint = {
//   // The label that will be displayed in the wallet selection modal
//   label: 'Crossmint',
//   injectedNamespace: 'ethereum',
//   checkProviderIdentity: ({ provider }) => !!provider && typeof provider.isConnected === 'function',
//   getIcon: async () => (await import('./svg/95070156.png')).default,
//   // Returns a valid EIP1193 provider. In some cases the provider will need to be patched to satisfy the EIP1193 Provider interface
//   getInterface: () => ({
//     provider: new CrossmintProvider(crossmintConnect, '0x14a33') //
//   }),
//   // A list of platforms that this wallet supports
//   platforms: ['desktop', 'mobile'],
//   /**
//    * A Url to link users to a download page for the wallet
//    * to be shown if not installed or available on the browser
//    */
//   externalUrl: 'https://www.crossmint.com/'
// }


const chains = [process.env.NODE_ENV === "production"? baseMainnet : baseGoerliMainnet]
//const wallets = [injectedModule({custom: [crossmint]}), coinbaseModule()]
const wallets = [injectedModule(), coinbaseModule()]

  const web3Onboard = init({
    connect: {
      autoConnectAllPreviousWallet: true
    },
    wallets,
    chains,
    theme: 'dark',
    appMetadata: {
      name: 'Ink Vault',
      icon: Masklogo,
      description: 'Own. Read. Resell.',
      recommendedInjectedWallets: [
        { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
        { name: 'MetaMask', url: 'https://metamask.io/' },
        { name: 'Crossmint', url: 'https://crossmint.com/' },
      ],
      agreement: {
        version: '0.1.0',
        termsUrl: 'http://www.inkvault.io/terms',
        privacyUrl: 'http://www.inkvault.io/privacypolicy'
      },
    },
    accountCenter: {
      desktop: {
        enabled: true, 
        position: 'bottomRight', 
        minimal: true, 
      },
      mobile: {
        enabled: true, 
        position: 'bottomRight', 
        minimal: true, 
      },
    },
    
  })


 

return (
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Web3OnboardProvider web3Onboard={web3Onboard}>
      <SignatureDialogProvider>
      <NetworkSwitchDialogProvider>
      <WalletProvider>
      
        <IssueDataProvider>
          <AnimatePresence>
          <MintingProvider>
          <BlockingOverlay key="blockingOverlay"/>
          
          <ExcelsiorBackground>
            <ExcelsiorInner />
            </ExcelsiorBackground>

            </MintingProvider>
          </AnimatePresence>
        </IssueDataProvider>
        </WalletProvider>
        </NetworkSwitchDialogProvider>
        </SignatureDialogProvider>
        </Web3OnboardProvider>
      </PersistGate>
    </Provider>
    <NotificationContainer/>
  </>
  
);
}

// class CrossmintProvider {
  
//   constructor(crossmintConnect, networkId) {
//     this.crossmintConnect = crossmintConnect;
//     this.networkId = networkId;
//     this.eventHandlers = {}; 
//   }

//   async request({ method, params }) {
//     try {
//       if (method === 'eth_accounts' || method === 'eth_requestAccounts') {
//         const address = await this.crossmintConnect.connect();
//         return [address];
//       } else if (method === 'eth_chainId') {
//         return this.networkId;
//       }else if (method === 'eth_getBalance') {
//         return null;
//       }
//     } catch (error) {
//       if (error) {
//         // Handle the error here
//         console.error('User closed the Crossmint login window');
       
//       }
//       throw error;
//     }

//     throw new Error(`Method ${method} not supported`);
//   }

//   on(event, handler) {
//     if (!this.eventHandlers[event]) {
//       this.eventHandlers[event] = [];
//     }
//     this.eventHandlers[event].push(handler);
//   }

//   emit(event, ...args) {
//     if (this.eventHandlers[event]) {
//       for (const handler of this.eventHandlers[event]) {
//         handler(...args);
//       }
//     }
//   }
// }
