import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Typography,
  Box
} from "@mui/material";

export const SignatureDialogContext = React.createContext();

export function SignatureDialogProvider({ children }) {
  const [isSignatureDialogOpen, setSignatureDialogOpen] = useState(false);
  const [resolveDialog, setResolveDialog] = useState();

  const openSignatureDialog = () => {
    return new Promise((resolve) => {
      setSignatureDialogOpen(true);
      setResolveDialog(() => resolve);
    });
  };

  const closeSignatureDialog = (decision) => {
    resolveDialog(decision);
    setSignatureDialogOpen(false);
  };

  return (
    <SignatureDialogContext.Provider value={openSignatureDialog}>
      {children}
      <Dialog open={isSignatureDialogOpen} onClose={() => closeSignatureDialog(false)} onClick={(e) => e.stopPropagation()}>
        <DialogTitle marginTop={1}>
          <Box>
            <Typography variant="h3" gutterBottom>SIGNATURE REQUEST</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Almost done! For your security, we need a digital signature from your wallet. It's just a way to confirm it's you. No funds will be moved or accessed. Please sign to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" m={1} spacing={1}>
            <Button 
              onClick={() => closeSignatureDialog(true)} 
              color="primary"
              variant="contained"
              size="large"
            >
              SIGN MESSAGE
            </Button>
            <Button 
              onClick={() => closeSignatureDialog(false)} 
              color="primary"
              size="small"
            >
              No thanks
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </SignatureDialogContext.Provider>
  );
}
