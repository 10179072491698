import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import baseImage from '../svg/Base_Network_Logo.svg';
import useMinter from '../web3/useMinter';
import { useMinting } from './MintingContext';
import { clearCart } from '../store/cartSlice';
import { makeRequest } from "../strapi/makeRequest";
import { ethers } from 'ethers';

import { setError } from '../store/errorSlice';

/* global BigInt */

const CartProcessor = (props) => {
  const cart = useSelector((state) => state.cart.cart);
 const { batchMint } = useMinter();
  const { isMinting, setIsMinting, setHasMinted, setErrorMinting, setErrorMessage } = useMinting();

  const dispatch = useDispatch();

  

  const processCart = useCallback(async () => {
    
    setIsMinting(true);

    let comicContracts = [];
    let numberOfTokensRequested = [];
    let valuesToSend = [];

    function errorHandling(error) {
      if (error && error.reason) {
          return error.reason;
      } else if (error?.response) {
          return error?.response?.data?.error?.message + ": " + error?.response?.data?.error?.details;
      } else if (error?.request) {
          return error?.request?.statusText;
      } else if (error?.message) {
          return error.message;
      } else {
          return error;
      }
  }
  
  

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const promises = cart.map(async (item) => {
      if (
        item.count !== null && 
        item.count !== undefined && 
        item.price !== null && 
        item.price !== undefined
      ) {
        try{
        // const fetchCollection = await makeRequest.get(
        //   process.env.NODE_ENV === "production" ? 
        //   `/api/nft-collections?filters[item][id][$eq]=${item.id}&fields[0]=contract`
        //   :
        //   `/api/nft-collections?filters[item][id][$eq]=${item.id}&fields[0]=testnet_contract`
        // );

        const fetchCollection = await makeRequest.get(
          process.env.NODE_ENV === "production" ? 
          `/api/nft-collections?filters[item][id][$eq]=${item?.id}&populate=contract`
          :
          `/api/nft-collections?filters[item][id][$eq]=${item?.id}&populate=testnet_contract`
        )
       
        const collection = fetchCollection?.data?.data[0]?.attributes;
        comicContracts.push(process.env.NODE_ENV === "production" ? collection.contract?.data?.attributes?.address : collection.testnet_contract?.data?.attributes?.address);
        numberOfTokensRequested.push(BigInt(item.count.toString()));

        const priceInWei = ethers.parseEther(item.price.toString());// Convert Ether to Wei
        const totalValue = priceInWei * BigInt(item.count.toString());
        valuesToSend.push(totalValue);
        }catch (error){
          dispatch(setError(error.message));
        }
      }
    });

    await Promise.all(promises);

    // console.log("Contracts:", comicContracts);
    // console.log("Number of Tokens:", numberOfTokensRequested);
    // console.log("Values to Send:", valuesToSend);


    if (
      comicContracts.length === 0 || 
      numberOfTokensRequested.length === 0 || 
      valuesToSend.length === 0 ||
      comicContracts.length !== numberOfTokensRequested.length ||
      comicContracts.length !== valuesToSend.length
    ) {
      setErrorMessage("Something went wrong. We are working to fix this issue.")
      setErrorMinting(true);
      setIsMinting(false);
      return;
    }

    
    
    try {
       const receipt = await batchMint(comicContracts, numberOfTokensRequested, valuesToSend);
        console.log("Transaction was successful:", receipt);
        dispatch(clearCart())
        setHasMinted(true);
      } catch (error) {
        console.error("Error processing cart:", error);
        setErrorMessage(errorHandling(error))
        setErrorMinting(true);
      }finally {
        setIsMinting(false);
      }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchMint, cart, dispatch]);

   

  return (
    <div>
      <Button 
        onClick={processCart} 
        endIcon={<img src={baseImage} alt="Base" style={{ width: '1em', height: '1em' }} />} 
        variant="contained" 
        color="primary" 
        style={{ fontWeight: 'bold', width: '100%' }}
        disabled={isMinting || props.disabled} 
      >
       {isMinting ? "Minting..." : "Buy with ETH"}
      </Button>
    </div>
  );
};

export default CartProcessor;
