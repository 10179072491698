import React, { useEffect, useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useMinting } from './MintingContext';
import {motion } from 'framer-motion';
import Lottie from "lottie-react";
import Confetti from 'react-confetti';

const MotionBox = motion(Box);
const MotionTypography = motion(Typography);

function BlockingOverlay({ isVisible }) {
  const {isMinting, hasMinted, setHasMinted, errorMinting, setErrorMinting, errorMessage} = useMinting();

  const [isAnimationComplete, setAnimationComplete] = useState(false);
  const [ecommerceAnimation, setEcommerceAnimation] = useState(null);
  const [alertAnimation, setAlertAnimation] = useState(null);
  const [successAnimation, setSuccessAnimation] = useState(null);

  useEffect(() => {
    import("../svg/ecommerce_white.json").then((module) => {
      setEcommerceAnimation(module.default);
    });

    import("../svg/alert.json").then((module) => {
      setAlertAnimation(module.default);
    });

    import("../svg/success.json").then((module) => {
      setSuccessAnimation(module.default);
    });
  }, []);


  useEffect(() => {
    setAnimationComplete(false);
    if (hasMinted || errorMinting) {
      const timer = setTimeout(() => {
        setHasMinted(false);
        setErrorMinting(false);
      }, errorMinting? 10000 : 6000);
      return () => clearTimeout(timer);
    }
  }, [hasMinted, errorMinting, setHasMinted, setErrorMinting]);

  if(!isMinting && !hasMinted && !errorMinting) return null;


  return (
    <MotionBox
      initial={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      animate={{ backgroundColor: hasMinted ? 'rgba(0, 30, 0, .7)' : errorMinting ? 'rgba(30, 0, 0, .7)' : 'rgba(0, 0, 0, 0.7)' }}
      exit={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999999  // high z-index to ensure it appears on top
      }}
    >
    {hasMinted &&
    <Confetti recycle={false} gravity={.1}/>
    }
    <Stack spacing={1}>
      <MotionTypography color='white' variant="h2">
        {hasMinted ? 'Mint successful' : errorMinting ? 'Error minting. Try again later.' : 'Minting in progress...'}
      </MotionTypography>
      {hasMinted &&
      <>
      <MotionTypography color='white' variant="body2">
        Your goodies are in the queue! Within the halfhour they should appear in your library. 
      </MotionTypography>
      </>
      }
      {errorMinting &&
      <MotionTypography color='white' variant="body2">
        {errorMessage}
      </MotionTypography>
      }
      
      {isMinting && 
      <Box
      style={{display:"block", marginLeft:"auto", marginRight: "auto"}}
      >
      <Lottie animationData={ecommerceAnimation} loop={true} style={{width:"10rem"}}/>
      </Box>
      }
      {hasMinted && 
      <Box
      style={{display:"block", marginLeft:"auto", marginRight: "auto"}}
      >
      <Lottie animationData={successAnimation}
      onComplete={() => setAnimationComplete(true)}
      loop={false} 
      style={{width:"10rem", opacity: isAnimationComplete ? 0 : 1}}

      />
      </Box>
      }
     
      {errorMinting && 
      <Box
      style={{display:"block", marginLeft:"auto", marginRight: "auto"}}
      >
      <Lottie animationData={alertAnimation} loop={true} style={{width:"5rem"}}/>
      </Box>
      }
      </Stack>
    </MotionBox>
  
  );
}

export default BlockingOverlay;