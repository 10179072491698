import { useCallback } from 'react';
import { ethers } from 'ethers';
import { makeRequest } from '../strapi/makeRequest';

/* global BigInt */

function useMinter() {
    // This assumes you have a global provider or are using MetaMask's provider.
    const provider = new ethers.BrowserProvider(window.ethereum);
    
    const batchMint = useCallback(async (comicContracts, numberOfTokensRequested, valuesToSend) => {
 
        try {

            const batchcontract = await makeRequest.get(
                `/api/batch-minter-contracts/`
              );

              const abi = batchcontract?.data?.data[0]?.attributes?.abi;
              const address = (process.env.NODE_ENV === "production" ? batchcontract?.data?.data[0]?.attributes?.address : batchcontract?.data?.data[0]?.attributes?.testnet_address);
            // console.log(batchcontract?.data?.data[0]?.attributes?.abi)

       

            if(!abi || !address){
                throw new Error("Something went wrong.");
            }


            const signer = await provider.getSigner();
            // const chainID = await signer.getChainId();
            // console.log(chainID)

             // Get the user's address
             const userAddress = await signer.address;

            const contract = new ethers.Contract(
                address,
                abi,
                signer
            );

            let totalValue = BigInt(0);
            valuesToSend.forEach(value => {
                totalValue += value;
            });
            const tx = await contract.batchMint(comicContracts, numberOfTokensRequested, valuesToSend, userAddress, {
                value: totalValue
            });

            const receipt = await tx.wait();
            return receipt;
        } catch (error) {
            console.error("Error during batch minting:", error);
            throw error;
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { batchMint };
}

export default useMinter;
