// ExcelsiorBackground.js
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { gsap } from "gsap";
import { HOME, LIBRARY, DETAILS, PURCHASING, READING } from './store/stageSlice'

export function ExcelsiorBackground({children}) {
  const mainStage = useSelector(state => state.stage.mainStage); 
  const bgRef = useRef(null); 

  useEffect(() => {
    let color;
    switch(mainStage) {
      case HOME:
        // color = 'linear-gradient(to bottom, #767687, #013F55)';
        //color = 'linear-gradient(to bottom right, #013F55, #673147)';
        color = 'linear-gradient(to bottom right, #013F55, #630330)';
        
        break;
        case DETAILS:
          color = 'linear-gradient(to bottom right, #105470, #0a3343)';
          break;
      case LIBRARY:
        color = 'linear-gradient(to bottom right, #781923, #20406A)';
        // color = 'linear-gradient(to bottom right, #013F55, #883000)';
        break;
      case PURCHASING:
      case READING:
        color = 'linear-gradient(to bottom, #0123401F, #0123402E)';
        break;
      default:
        color = 'linear-gradient(to bottom, #0123401F, #0123402E)';
        break;
    }
    gsap.to(bgRef.current, {
    background: color,
      duration: 1, 
    });
  }, [mainStage]);

  return (
    <div ref={bgRef} style={{width: '100vw', height: '100vh'}}>
      {children}
    </div>
  );
}
