import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fab, Box, Button, Tooltip } from '@mui/material';
import { LibraryBooks, AccountBalanceWallet, InfoOutlined } from '@mui/icons-material';
import WalletContext from '../store/WalletContext';
import {Zoom} from '@mui/material';
import YouTubeModal from '../components/YouTubeModal'; 
import {setLibrary} from '../store/stageSlice.js'
import {
  GetMediaMaxWidthSmallDevice,
} from "../store/ExcelsiorDataContext";


export default function WalletFab(props) {
  const { connect, connecting } = useContext(WalletContext);
  const dispatch = useDispatch();
  const address = useSelector((state) => state.auth.address);
  const loggedIn = Boolean(address);
  const matchesSmallDevice = GetMediaMaxWidthSmallDevice();
  const [showYouTubeModal, setShowYouTubeModal] = useState(false); 

  // handle the wallet connect event
  const handleConnectWallet = () => {
    if (loggedIn) {
      dispatch(setLibrary(null));
    } else {
      connect();
    }
  };

  const handleExplanationModule= () => {
    setShowYouTubeModal(true); 
  }

  return (
    <>
      {props.type === "long" ?
     <>
     <Box
     sx={{
      display:'flex',
      
      }}
     >
        <Button
          variant="contained"
          color="info"
          onClick={handleConnectWallet}
          disabled={connecting}
          endIcon={<AccountBalanceWallet />}
          fullWidth>
          Connect Wallet
        </Button>
        <Tooltip title="Don't have a wallet or not sure how to pay? Click here!" placement='top' leaveDelay={200} TransitionComponent={Zoom} arrow>
        <Button
        sx={{marginLeft: '1%'}}
          variant="contained"
          color="warning"
          onClick={handleExplanationModule}
          // disabled={connecting}
          endIcon={<InfoOutlined />}
          >
          HOW?
        </Button>
        </Tooltip>
        </Box>
       </>
        :
        <>
        <Tooltip title={loggedIn ? "Library" : "Connect Wallet"} enterDelay={2000}>
        <span>
        <Fab
          onClick={handleConnectWallet}
          disabled={connecting}
          color="secondary"
          variant="extended">
          {loggedIn ? <LibraryBooks /> : <AccountBalanceWallet />}
          {loggedIn && !matchesSmallDevice && <Box ml={0.5}>Library</Box>}
        </Fab>
        </span>
        </Tooltip>
        </>
      }
      <YouTubeModal 
        open={showYouTubeModal}
        onClose={() => setShowYouTubeModal(false)}
      />
    </>
  );
}
