import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, List, ListItemButton, ListItemText, Button, Grid, Typography, Link, DialogContentText, useTheme, useMediaQuery, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const YouTubeModal = ({ open, onClose }) => {

   const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Array of videos with their IDs and Titles
  //https://youtu.be/wRlA4dnX6hc
  const videos = [
    { id: "wRlA4dnX6hc", title: "Start Here: How to Buy Comics on Ink Vault" },
    { id: "c88d6lIcs_I", title: "Set Up Coinbase Wallet (Desktop)" },
    { id: "3ccsmANhly8", title: "Set Up Coinbase Wallet (Phone)" },
    { id: "jY3YT7KtCFU", title: "Coinbase Wallet Tutorial (Short)" },
    { id: "MxmdGUyF7Zw", title: "Coinbase Wallet Tutorial (Long)" },
    { id: "xWdyWpgq7LE", title: "Coinbase Beginner's Guide" },
    { id: "OKt_Z8OVOFM", title: "Bridge ETH to Base in Metamask" },
  ];

  const [selectedVideo, setSelectedVideo] = useState(videos[0].id);

  const moreLinks = [
    { title: "Newbies Start Here", url: "https://www.youtube.com/watch?v=0ctngpAJXw0&list=PL0zEUTVaoPOw2WTcEXqX4elCY_EZLZEn9" },
    { title: "What is ETH?", url: "https://www.coinmama.com/blog/ethereum-for-dummies/" },
    { title: "What is a Crypto Wallet?", url: "https://www.coinbase.com/learn/crypto-basics/what-is-a-crypto-wallet" },
    { title: "Coinbase Wallet", url: "https://www.coinbase.com/wallet" },
    { title: "Metamask Wallet", url: "https://metamask.io/download/" },
    { title: "Bridge ETH to Base", url: "https://help.coinbase.com/en/wallet/bridging" },
    // Add more links here
  ];


  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="youtube-video-title" fullWidth maxWidth="lg" fullScreen={isMobile}>
      <DialogTitle id="youtube-video-title" sx={{ mt: 1, p: 1 }}>
      How to Buy Comics Using Crypto on Ink Vault
        <Button sx={{ position: 'absolute', right: 8, top: 8, color: 'grey' }} onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent dividers style={{ overflowY: 'auto', maxHeight: '80vh' }}>
     

        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', height: isMobile ? 'auto' : '450px', marginBottom: '1rem' }}>
  {isMobile ? (
    <Accordion style={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {videos.find(video => video.id === selectedVideo)?.title || 'Select a Video'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List style={{ width: '100%', overflow: 'auto' }}>
            {videos.map((video, index) => (
                <ListItemButton 
                key={index} 
                onClick={() => setSelectedVideo(video.id)}
                selected={video.id === selectedVideo}
                sx={{ bgcolor: video.id === selectedVideo ? 'rgba(0, 0, 0, 0.08)' : 'none' }}
                >
                <ListItemText primary={video.title} primaryTypographyProps={{ variant: 'caption' }} />
                </ListItemButton>
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  ) : (
    <List style={{ minWidth: '200px', overflow: 'auto' }}>
    {videos.map((video, index) => (
                <ListItemButton 
                key={index} 
                onClick={() => setSelectedVideo(video.id)}
                selected={video.id === selectedVideo}
                sx={{ bgcolor: video.id === selectedVideo ? 'rgba(0, 0, 0, 0.08)' : 'none' }}
                >
                <ListItemText primary={video.title} primaryTypographyProps={{ variant: 'caption' }} />
                </ListItemButton>
            ))}
    </List>
  )}
  <iframe style={{ 
    flex: 1, 
    minHeight: `${Math.min(window.innerHeight * 0.3, 800)}px`,
    width: '100%' 
    }} src={`https://www.youtube.com/embed/${selectedVideo}`} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
</div>

<DialogContentText sx={{ m: 0, pl: 1, pb: 1 }} variant="caption">
        1. Setup an account on an exchange. Coinbase is the easiest for first-time users.<br/><br/>
        2. Install a crypto wallet like Coinbase Wallet or Metamask. <br/><br/>
        3. Within the Coinbase Wallet, you can buy Ethereum (ETH), which is the cryptocurrency accepted on Ink Vault.<br/><br/>
        4. You'll then bridge it to Base, an Ethereum Layer 2 chain where all crypto transactions are handled for Ink Vault due to its low costs for our customers.<br/><br/>
        5. Once that's complete, connect your wallet to Ink Vault to buy comics with ETH.
        </DialogContentText>
        <DialogContentText sx={{ m: 0, pl: 1, pb: 1 }} variant="caption">
            The resources included in this section are intended to assist you with the setup process. Please note these resources are not owned or managed by Ink Vault and are provided solely for your convenience. Ink Vault is not responsible for their content.
        </DialogContentText>
        <Grid container spacing={0.1} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">External Links:</Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-start">
            {moreLinks.map((link, index) => (
                <Box key={index} sx={{
                flex: isMobile ? '1 1 auto' : 'initial',
                minWidth: isMobile ? '100%' : 'auto',
                p: 1
                }}>
                <Link href={link.url} target="_blank" rel="noopener noreferrer" variant="caption">
                    {link.title}
                </Link>
                </Box>
            ))}
            </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default YouTubeModal;
