import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";



export const NetworkSwitchDialogContext = React.createContext();

export function NetworkSwitchDialogProvider({ children }) {
  const [isNetworkSwitchDialogOpen, setNetworkSwitchDialogOpen] = useState(false);
  const [resolveDialog, setResolveDialog] = useState();

  const [isTableOpen, setTableOpen] = useState(false);


  const openNetworkSwitchDialog = () => {
    return new Promise((resolve) => {
      setNetworkSwitchDialogOpen(true);
      setResolveDialog(() => resolve);
    });
  };

  const closeNetworkSwitchDialog = (decision) => {
    resolveDialog(decision);
    setNetworkSwitchDialogOpen(false);
  };

  const handleTableToggle = () => {
    setTableOpen(!isTableOpen);
  };

  return (
    <NetworkSwitchDialogContext.Provider value={openNetworkSwitchDialog}>
      {children}
      <Dialog open={isNetworkSwitchDialogOpen} onClose={() => closeNetworkSwitchDialog(false)}>
        <DialogTitle marginTop={1}>
          <Box>
            <Typography variant="h3" gutterBottom>Switch to Base Mainnet</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={1}>
          Ink Vault runs on Base Mainnet. We'll try to switch your network to "Base Mainnet" automatically. If it's not already in your wallet, we'll prompt you to add it using the details below.          </DialogContentText>
          

          <Box 
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: "1rem" }} 
            onClick={handleTableToggle}
            >
            <IconButton
                aria-expanded={isTableOpen}
                aria-label="show more"
                sx={{ color: 'inherit', padding: '0' }}
            >
                <ExpandMoreIcon sx={{ transform: isTableOpen ? 'rotate(180deg)' : 'none' }} />
            </IconButton>
            <Typography variant="body2">
                {isTableOpen ? "Hide" : "Expand for"} Base Mainnet network details
            </Typography>
            </Box>

          <Collapse in={isTableOpen} timeout="auto" unmountOnExit>
            <Table sx={{ borderCollapse: 'collapse' }}>
    <TableBody>
        <TableRow sx={{ backgroundColor: '#000' }}>
            <TableCell sx={{ fontWeight: 'bold', padding: '8px', borderBottom: '1px solid #ddd' }}>
                Network Name
            </TableCell>
            <TableCell sx={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                Base Mainnet
            </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: '#000' }}>
            <TableCell sx={{ fontWeight: 'bold', padding: '8px', borderBottom: '1px solid #ddd' }}>
                Description
            </TableCell>
            <TableCell sx={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                The public mainnet for Base.
            </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: '#000' }}>
            <TableCell sx={{ fontWeight: 'bold', padding: '8px', borderBottom: '1px solid #ddd' }}>
                RPC Endpoint
            </TableCell>
            <TableCell sx={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                https://mainnet.base.org<br/>Rate limited and not for production systems.
            </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: '#000' }}>
            <TableCell sx={{ fontWeight: 'bold', padding: '8px', borderBottom: '1px solid #ddd' }}>
                Chain ID
            </TableCell>
            <TableCell sx={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                8453
            </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: '#000' }}>
            <TableCell sx={{ fontWeight: 'bold', padding: '8px', borderBottom: '1px solid #ddd' }}>
                Currency Symbol
            </TableCell>
            <TableCell sx={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                ETH
            </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: '#000' }}>
            <TableCell sx={{ fontWeight: 'bold', padding: '8px' }}>
                Block Explorer
            </TableCell>
            <TableCell sx={{ padding: '8px' }}>
                https://basescan.org
            </TableCell>
        </TableRow>
    </TableBody>
</Table>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" m={1} spacing={1}>
            <Button 
              onClick={() => closeNetworkSwitchDialog(true)} 
              color="primary"
              variant="contained"
              size="large"
            >
              SWITCH NETWORK
            </Button>
            <Button 
              onClick={() => closeNetworkSwitchDialog(false)} 
              color="primary"
              size="small"
            >
              No thanks
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </NetworkSwitchDialogContext.Provider>
  );
}
